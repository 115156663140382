import React from 'react'

export default function Stepone() {
    const stepone = {
        margin: "50px 10px -30px -0px"
    }
    return (
        <div style={stepone}>
            <strong>Step 1:</strong> Search your document in our internal database
        </div>
    )
}
