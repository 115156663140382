import Header from "./Components/Header";
import SearchBar from "./Components/SearchBar";
import Footer from "./Components/Footer";
function App() {
  return (
    <>
      <Header />
      <SearchBar />
      <Footer />
    </>
  );
}

export default App;
