import React from 'react'

export default function Stepthree() {
    return (
        <div>
            <strong>Step 3:</strong> Not able to find the desired info/records? Let's file the RTI application for you.
            
        </div>
    )
}
