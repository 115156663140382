import React from 'react'

export default function Chatbot() {
    const box = {
        width: "100%",
        height: "450px",
        "justify-content": "center",
        margin: "20px 0px 0px 10px",
        border: "solid black"
    }
    const iframestyle = {
        width: "100%",
        height: "100%",
        border: "0px",
        padding: "0px"
    }
    const url = window.location.href
    let source
    switch (url) {
        case "https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=te&_x_tr_hl=en-GB":
            source = "https://rtibot--4f4a6-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=te&_x_tr_hl=en-US"
            break;
        case "https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=hi&_x_tr_hl=en-GB":
            source = "https://rtibot--4f4a6-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=hi&_x_tr_hl=en-US"
            break;

        case "https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=mr&_x_tr_hl=en-GB":
            source = "https://rtibot--4f4a6-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=mr&_x_tr_hl=en-GB"
            break;

        case "https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=ta&_x_tr_hl=en-GB":
            source = "https://rtibot--4f4a6-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=ta&_x_tr_hl=en-US"
            break;

        case "https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=gu&_x_tr_hl=en-GB":
            source = "https://rtibot--4f4a6-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=gu&_x_tr_hl=en-US"
            break;

        default:
            source = "https://rtibot-4f4a6.web.app/"
            break;
    }

    return (
        <div className='' style={box}>
            <iframe src="http://20.70.8.151" title="Bot" style={iframestyle}></iframe>
        </div>

    )
}
