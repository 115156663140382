import React from 'react'
import { useState, useEffect } from 'react';
import axios from "axios"
import Result from './Result';
import Stepone from "./Stepone";
import Steptwo from "./Steptwo";
import Stepthree from "./Stepthree";
import Chatbot from "./Chatbot";
export default function SearchBar() {
  const baseURL = process.env.REACT_APP_BACK_URL;
  const [data, setData] = useState([]); //type is array, see the postman result for this
  const [query, setQuery] = useState("");//as this is the string
  const submit = async (e) => {
    e.preventDefault()
    if (!query) {
      alert('query cant be blank');
    } else {
      console.log(query)
      axios.get(baseURL, { params: { search: query } }).then((response) => {
        setData(response.data);
        console.log(data);
      })
    }
  }
  console.log(data);
  const pss = {
    width: "150%"
  }
  const buttonstyle = {
    color: "#F3C40C",
    "border-color":"#F3C40C"
  }
  return (
    <div className="container" >
      <Stepone />
      <form action="" className="form-group d-flex mt-5" onSubmit={submit}>
        <input type="text" name="" id="" value={query} onChange={(e) => { setQuery(e.target.value) }} className="form-control w-100 mr-2" />

        <button className="btn ml-2" style={buttonstyle}>Search</button>
      </form>
      <div>
        {
          data.map((data) => {
            return <Result key={data.id} queryResult={data} />
          })}
      </div>
      <Steptwo />
      <div class="gcse-search" style={pss} ></div>
      <Stepthree />
      <Chatbot />
    </div>
  )
}
