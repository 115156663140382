import React from 'react'
export default function Result({queryResult}) {
  const baseURL = process.env.REACT_APP_BACK_URL;
  const url = `${baseURL}download/${queryResult.id}`
  return (

    //empty open or close
    <>
      <div className="div">
        {queryResult.name}
      </div>
      <div className="div">
        {queryResult.id}
      </div>
      <a className="btn btn-outline-primary" target="_blank" href={url}>
        Open 
      </a>
    </>
  )
}
