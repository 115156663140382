import React from 'react'
import logo from "./Image/rtibot2.png"
export default function Header() {
    const logo_style = {
        width: "150px",
        height: "60px",
        background: "transparent",
        margin: "5px 10px 5px 10px"
    }
    const navbar_style = {
        "text-align": "center",
        "justify-content": "center!important"
    }
    const language_style = {
        padding: "20px 0 0 0"
    }
    const language_button_style = {
        border: 0
    }
    const url = window.location.href
    console.log(url)
    return (

        <div className="navbar navbar-expand-lg navbar-light bg-light" style={navbar_style}>

            <button className="navbar-brand" style={language_button_style}><img src={logo} style={logo_style} alt="" /></button>


            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <button className="btn btn-outline-primary ml-2" style={language_button_style} onClick={() => { window.location.assign("https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=hi&_x_tr_hl=en-GB") }}>Hindi</button>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-outline-primary ml-2" style={language_button_style} onClick={() => { window.location.assign("https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=mr&_x_tr_hl=en-GB") }}>Marathi</button>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-outline-primary ml-2" style={language_button_style} onClick={() => { window.location.assign("https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=ta&_x_tr_hl=en-GB") }}>Tamil</button>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-outline-primary ml-2" style={language_button_style} onClick={() => { window.location.assign("https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=gu&_x_tr_hl=en-GB") }}>Gujarati</button>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-outline-primary ml-2" style={language_button_style} onClick={() => { window.location.assign("https://rtiwala-330917.web.app/") }}>English</button>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-outline-primary ml-2" style={language_button_style} onClick={() => { window.location.assign("https://rtiwala--330917-web-app.translate.goog/?_x_tr_sl=en&_x_tr_tl=te&_x_tr_hl=en-GB") }}>Telugu</button>
                    </li>
                </ul>

            </div>

        </div>
    )
}
