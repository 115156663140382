import React from 'react'
import "./css/style.css"
export default function Footer() {
    const footer_style = {
        // position: "fixed",
        // bottom: 0,
        // left: 0,
        width: "100%",
        "margin-top": "30px"
    };
    const text_style = {
        "text-align": "center",
        "text-transform": "uppercase",
        "font-family": "Georgia",
        "padding": "10px"
    }
    const icon_style = {
        "margin": "10px",
        "padding": "10px",
    }
    const allicon_style = {
        "justify-content": "center"
    }
    const footer_icon_style = {
        border: 0,
        "background": "transparent" 
    }
    return (
        <div className="bg-dark" style={footer_style} >
            <div className="text-light align-center" style={text_style} >Keep in Touch</div>
            <div className=" d-flex" style={allicon_style}>
                <div className=" " style={icon_style}>

                    <button style={footer_icon_style}  onClick={() => { window.location.assign("https://www.facebook.com/RTIwalaOfficial/") }} >
                        <i aria-hidden="true" className="fab fa-facebook"></i>
                    </button>
                </div>

                <div className=" " style={icon_style}>

                    <button style={footer_icon_style} onClick={() => { window.location.assign("https://instagram.com/rtiwala") }} >
                        <i aria-hidden="true" className="fab fa-instagram"></i>
                    </button>

                </div>
                
                <div className=" " style={icon_style}>

                    <button style={footer_icon_style} onClick={() => { window.location.assign("https://www.linkedin.com/company/rtiwala/") }} >
                        <i aria-hidden="true" className="fab fa-linkedin"></i>
                    </button>

                </div>
                <div className=" " style={icon_style}>

                    <button style={footer_icon_style} onClick={() => { window.location.assign("https://medium.com/rtiwala/") }} >
                        <i aria-hidden="true" className="fab fa-medium"></i>
                    </button>

                </div>

                <div className=" " style={icon_style}>

                    <button style={footer_icon_style} onClick={() => { window.location.assign("https://www.pinterest.com/RTIwala/") }} >
                        <i aria-hidden="true" className="fab fa-pinterest"></i>
                    </button>

                </div>
                <div className=" " style={icon_style}>

                    <button style={footer_icon_style} onClick={() => { window.location.assign("https://twitter.com/rtiwala") }} >
                        <i aria-hidden="true" className="fab fa-twitter"></i>
                    </button>

                </div>
                <div className=" " style={icon_style}>

                    <button style={footer_icon_style} onClick={() => { window.location.assign("https://help.rti.link") }} >
                        <i aria-hidden="true" className="fab fa-whatsapp"></i>
                    </button>

                </div>
                <div className=" " style={icon_style}>

                    <button style={footer_icon_style} onClick={() => { window.location.assign("https://tv.rti.link") }} >
                        <i aria-hidden="true" className="fab fa-youtube"></i>
                    </button>

                </div>
                
            </div>
        </div>
    )
}
